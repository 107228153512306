<script setup lang="ts">
interface Props {
  amount: string
  currency: string
}

const props = defineProps<Props>()

const { formatPrice } = useFormatPrice()
const formattedAmount = computed(() => formatPrice(props.amount, props.currency))
</script>

<template>
  <h5 class="text-h5">
    {{ formattedAmount }}
  </h5>
</template>

<style scoped lang="postcss">

</style>
